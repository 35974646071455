import { styled } from "styled-components";
import footerBGImage from "../../assets/footer-image.png";
import { devices } from "utils/theme";
import { BaseTypography } from "components/common/BaseTypography";
import { flexRowAlignCenterJustifySpaceBetween } from "utils/styles";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";

export const HomeStyled = styled.div`
  min-height: 100vh;
  background: #fcfcfc;
  overflow-x: hidden;
  /* max-width: 1160px !important; */
`;

export const HeroContainer = styled.div`
  width: 100%;
  background-color: white;
  padding-bottom: 10px;
  margin-bottom: 20px;

  background-color: white;
`;

export const HeroStyled = styled.div`
  max-width: 1200px;
  margin: 0px auto 30px;
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px 30px 0px;
  height: auto;
  margin-bottom: 0px;
  z-index: 0;
  background: rgba(0, 0, 0, 0.6);
  @media (${devices.laptop}) {
    flex-direction: row;
    background: white;
    margin-bottom: 100px;
  }
  position: relative;

  & .left-contents {
    width: 100%;
    margin-right: 20px;
    position: relative;
    z-index: 50;
    padding: 50px 0px;

    @media (${devices.laptop}) {
      width: 520px;
      background: transparent;
      padding: 0px;
    }
    & .camera-compliance {
      color: seashell;
      margin-bottom: 12px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: unset;

      @media (${devices.mobileSM}) {
        line-height: normal;
      }

      @media (${devices.laptop}) {
        color: #818181;
      }
    }
    & .title {
      color: ivory;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      @media (${devices.mobileSM}) {
        line-height: 50px; /* 166.667% */
      }
      @media (${devices.laptop}) {
        color: #333333;
      }
    }
    & .description {
      color: #fcfcfc;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      @media (${devices.mobileSM}) {
        line-height: 36px; /* 180% */
      }
      @media (${devices.laptop}) {
        color: #818181;
      }
    }

    & button {
      background: #167a8b;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 12px;
      font-size: 1rem;

      & svg {
        margin-left: 10px;
        width: 23px;
        height: 22px;
      }

      &:hover {
        /* background: #167a8b; */
        box-shadow: RGBA(22, 122, 139, 0.4) 5px 5px;

        color: #fff;
      }
    }
  }
  & .right-contents {
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 20px;
    flex: 1;
    img {
      height: 90%;
      width: 90%;
      opacity: 0.2;
    }
    @media (${devices.mobileSM}) {
      right: 20px;

      img {
        height: 400px;
        width: 100%;
      }
    }
    @media (${devices.laptop}) {
      position: relative;
      top: 0px;
      right: 0px;
      justify-content: end;
      display: flex;

      img {
        height: 500px;
        width: 500px;
        opacity: 1;
      }
    }
  }
`;
export const InfoContainerStyled = styled.div`
  display: block;
  padding: 30px 30px 0px;
  max-width: 1200px;

  @media (${devices.tablet}) {
    max-width: 1160px;
    margin: 0px auto 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 50px;
  }

  & .user-info-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #efefef;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    @media (${devices.mobileSM}) {
      flex-direction: row;
      gap: 40px;
    }

    @media (${devices.tablet}) {
      margin: 30px 0px;
      flex-direction: column;
      gap: initial;
      max-width: 300px;
      background-color: initial;
    }

    & .svg-wrapper {
      background: #167a8b;
      padding: 22px;
      border-radius: 50%;
      margin-bottom: 10px;
      & svg {
        width: 32px;
        height: 29px;
        color: white;
      }
    }

    div:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .user-position {
      color: #243d44;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    & .user-descripton {
      font-size: 0.8rem;
      font-weight: 400;
      color: #818181;
    }

    & .learn-more {
      color: #167a8b;
      font-weight: 700;
    }
  }
`;

export const TestimonialContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;
  padding: 0 50px;

  & .title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 30px;
    color: #167a8b;
    text-align: center;
  }

  & .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    margin: 0 auto;

    & .testimonial {
      width: 100%;
      padding: 40px;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      & .profile {
        display: flex;

        & .img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
          object-fit: cover;
        }
      }
    }
  }
`;
export const FeatureContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;
  padding: 0 50px;

  & .feature-btn {
    :hover {
      box-shadow: #167a8b 0px 14px 28px, #167a8b 0px 10px 10px;
    }
  }

  & .title {
    text-align: center;
    color: #167a8b;
    margin-bottom: 40px;

    & .header {
      color: #167a8b;
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  & .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    margin: 0 auto;

    & .features {
      width: 100%;
      margin-bottom: 50px;

      transition: opacity 1s ease-in-out;

      & .feature1 {
        width: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 20px;
      }
      & .img {
        height: 140px;
        width: 250px;
        object-fit: cover;
        margin: 0 auto;
        border-radius: 5px;
      }

      &:hover {
        transform: translateY(-5px);
        border-top: 5px solid #167a8b;

        & .feature1 {
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-radius: 6px;
        }
        & .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          align-items: center;
          height: 50px;
        }

        & .img {
          height: 100px;
          margin-top: 80px;
        }
      }
    }
  }
`;

export const ConnectContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;

  & .title {
    text-align: center;
    margin-bottom: 30px;
    color: #167a8b;
    font-size: 1.8rem;
    font-weight: 600;
  }
  & .img {
    width: 100px;
    height: 50px;
  }

  & .Wrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${footerBGImage});
  background-repeat: no-repeat;
  background-size: cover;

  & .footer-contents {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 82px 30px;

    & .title {
      color: #167a8b;
      font-size: 23px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 24px;
      text-align: center;
    }
    & .desc {
      color: #576874;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 10px;

      line-height: 24px;
      margin-bottom: 30px;
    }

    & button {
      background: #167a8b;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 12px;
      font-size: 1rem;

      & svg {
        margin-left: 10px;
        width: 23px;
        height: 22px;
      }

      &:hover {
        background: #167a8b;
        color: #fff;
      }
    }
  }
  & .housebuild-link {
    color: #fff;

    text-decoration: underline !important;
    :hover {
      color: blue;
    }
  }
  & .footer-content-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

    @media (max-width: 900px) {
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      gap: 20px;
    }
  }
  & .follow {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 900px) {
    }
  }

  & .reserved-wrapper {
    width: 100%;
    background: #07121d;

    padding: 30px 20px;

    font-size: 1rem;

    & .reserved {
      color: #fff;

      & .term {
        cursor: pointer;
      }
      & .privacy {
        cursor: pointer;
      }
    }
    & .reserved-copy {
      color: gray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    & .footer-icons {
      /* display: flex;
      text-align: center;
      justify-content: center; */
      gap: 15px;
      margin-bottom: 20px;

      & .img {
        height: 40px;
        width: 40px;
        text-align: center;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    & .links {
      display: flex;
      justify-content: space-between;
      max-width: 1160px;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
`;

// export const FooterContentWrapper = styled.div`
//   display: flex;
//   flex-direction: space-around;
//   margin: 0 auto;
// `;

export const IconStyled = styled.span`
  color: var(--white);
`;
export const ExploreBtnWrapper = styled.div`
  ${flexRowAlignCenterJustifySpaceBetween}
`;

export const ExploreText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--white);
`;
export const ContactUsText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--white);
`;

export const FandQContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;
  padding: 0px 40px;

  & .ant-collapse-header {
    padding: 50px 20px !important;
  }

  & .ant-collapse-header-text {
    font-size: 16px;
    font-weight: bold;
  }

  & .title {
    text-align: center;
    margin-bottom: 30px;
    color: #167a8b;
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;
  padding: 0 50px;

  & .title {
    text-align: center;
    margin-bottom: 30px;
    color: #167a8b;
    font-size: 1.8rem;
    font-weight: 600;
  }

  & .main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 35px;
    width: 100%;
    margin: 0 auto;

    & .container {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px;
      width: 100%;
      border-radius: 10px;

      & .text {
        color: gray;
        text-align: center;
      }
      & .container-title {
        text-align: center;
      }

      & .image {
        width: 30px;
        height: 30px;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
  }
`;

export const AboutUsStyled = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-direction: column-reverse;
  padding: 0px 20px;

  @media (${devices.laptop}) {
    flex-direction: row;
    background: transparent;
    margin-bottom: 100px;
  }
  position: relative;

  & .left-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    @media (${devices.mobileSM}) {
      img {
        height: 100%;
        width: 70%;
      }
    }
    @media (${devices.laptop}) {
      width: 536px;

      img {
        height: 536px;
        width: 100%;
        opacity: 1;
      }
    }
  }

  & .right-contents {
    flex: 1;
    width: 100%;
    margin-left: 0px;
    position: relative;
    padding: 50px 0px;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (${devices.laptop}) {
      width: 520px;
      background: transparent;
      padding: 50px 20px 0px;
      margin-left: 20px;
    }
    & .about-us {
      color: #818181;
      margin-bottom: 12px;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & .title {
      color: #333333;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      @media (${devices.mobileSM}) {
        line-height: 50px;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 68px;
        height: 6px;
        background: var(--primary);
      }
    }
    & .description {
      color: #818181;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      @media (${devices.mobileSM}) {
        line-height: 36px; /
      }
    }
  }
`;
