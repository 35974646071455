import { FooterStyled } from "./style";
import { Typography } from "antd";

//@ts-ignore
import { ReactComponent as RIGHT_ARROW_SVG } from "../../assets/arrow-right.svg";
//@ts-ignore
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { BiLogoLinkedin } from "react-icons/bi";
import { FaExternalLinkAlt } from "react-icons/fa";

type Props = {};

const Footer = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <FooterStyled>
        <div className="reserved-wrapper">
          <div className="footer-content-wrapper">
            <div className="follow">
              <Typography className="reserved"> Registered Address</Typography>
              <Typography className="reserved">
                Camera Compliance Ltd. Carrick Road, Drumshanbo, Co. Leitrim,
                Ireland
              </Typography>
            </div>

            <div className="follow">
              <Typography className="reserved">Company</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                {/* <Typography className="reserved" style={{ color: "#fff" }}> */}
                <Typography
                  onClick={() => navigate("/terms")}
                  className="reserved"
                  style={{ cursor: "pointer" }}
                >
                  {t("footer.footer-term")}
                </Typography>{" "}
                <Typography
                  onClick={() => navigate("/privacy")}
                  className="reserved"
                  style={{ cursor: "pointer" }}
                >
                  {t("footer.footer-privacy")}
                </Typography>{" "}
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/disclaimer")}
                  className="reserved"
                >
                  Disclaimer
                </Typography>
                {/* </Typography> */}
              </div>
            </div>
            <div className="follow">
              <Typography className="reserved">Follow</Typography>
              <a
                href="https://www.linkedin.com/company/camera-compliance/"
                target="_blank"
                rel="noreferrer"
                className="footer-icons"
              >
                <BiLogoLinkedin className="img" />
              </a>
            </div>
          </div>
          <Typography.Text className="reserved-copy">
            © 2025 Camera Compliance Limited | Registered in Ireland company
            number: 623650
          </Typography.Text>
          {/* <div className="footer-icons">
            <a
              href="https://www.linkedin.com/company/camera-compliance/"
              target="_blank"
              rel="noreferrer"
            >
              <BiLogoLinkedin className="img" />
            </a>
          </div> */}

          {/* <Typography.Text className="reserved">
            <span className="term" onClick={() => navigate("/terms")}>
              {t("footer.footer-term")}
            </span>{" "}
            -{" "}
            <span className="privacy" onClick={() => navigate("/privacy")}>
              {t("footer.footer-privacy")}
            </span>{" "}
            -{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/disclaimer")}
            >
              Disclaimer
            </span>
          </Typography.Text>
          <br /> */}
          {/* <Typography.Text className="reserved-copy">
            © 2023 Camera Compliance Limited | Registered in Ireland company
            number: 623650
          </Typography.Text> */}
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;
